/* eslint-disable consistent-return */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useJWContext, JWContext } from 'src/shoppe/hooks/useJWContext';

/**
 * Used to insert new elements into the DOM within the context of a JWPlayer
 */
export const JWCustomElem: React.FC<{
  className?: string;
  id: string;
  insertAfterSelector?: string;
  children: React.ReactElement;
}> = ({ className = '', id, insertAfterSelector = '.jw-hidden-accessibility', children }) => {
  const [ prevCustomElement, setPrevCustomElement ] = React.useState<HTMLDivElement | null>( null );
  const [ prevCustomElementId, setPrevCustomElementId ] = React.useState( null );

  const ctx = useJWContext();

  React.useEffect(() => {
    if ( !ctx.isReady ) return;

    let customElement = prevCustomElement;

    if ( prevCustomElementId !== id ) {
      // cleanup if id changed
      if ( customElement ) {
        ReactDOM.unmountComponentAtNode( customElement );
        customElement?.remove();
      }

      customElement = document.createElement( 'div' );
      customElement.className = className;
      customElement.id = id;

      setPrevCustomElementId( id );
      setPrevCustomElement( customElement );

      document
        .querySelector( `#${ctx.playerId} ${insertAfterSelector}` )
        ?.insertAdjacentElement( 'afterend', customElement );
    }

    if ( children && customElement ) {
      // share the same context of the parent provider
      ReactDOM.render(
        <JWContext.Provider value={ctx}>{children}</JWContext.Provider>,
        customElement
      );
    }
  }, [ id, ctx, children ]);

  return null;
};
