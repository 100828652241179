import React from 'react';

import { JWSelector } from 'src/shoppe/elements/JWSelector';
import { IEventSource } from 'src/shoppe/services/JWPlayer';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';

export const ReplayControl = () => {
  const { isComplete, source, updateContext } = useJWContext();

  const handleClick = React.useCallback(() => {
    // the display icon can be a reset, pause, play, or loading"
    // to ennsure this is a replay interaction only set if the video is complete
    updateContext({
      source: isComplete ? IEventSource.REPLAY : source,
    });
  }, [ isComplete, source ]);

  return <JWSelector selector=".jw-icon-display" onClick={handleClick} />;
};
