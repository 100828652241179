import * as React from 'react';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';

/**
 * Used to select and alter preexitising JW player dom elements
 */
export const JWSelector: React.FC<{
  className?: string;
  insertBefore?: string;
  resetEventHandlers?: boolean;
  selector: string;
  style?: Partial<CSSStyleDeclaration>;
  onClick?: () => void;
}> = ({ className, insertBefore, resetEventHandlers, selector, style, onClick }) => {
  const { isReady, playerId } = useJWContext();

  React.useEffect(() => {
    if ( isReady ) {
      const elem = document.querySelector( `#${playerId} ${selector}` ) as HTMLDivElement;
      const adjacent = document.querySelector( `#${playerId} ${insertBefore || selector}` );

      if ( elem && adjacent ) {
        const insertElem = resetEventHandlers ? ( elem.cloneNode( true ) as HTMLDivElement ) : elem;
        const created = adjacent.insertAdjacentElement( 'beforebegin', insertElem ) as HTMLDivElement;

        if ( resetEventHandlers ) {
          elem.remove();
        }

        if ( className ) created.classList.add( ...className.split( ' ' ));
        if ( onClick ) created.addEventListener( 'click', onClick );

        Object.keys( style || {}).forEach(( key: any ) => {
          created.style[key] = style[key];
        });
      }
    }
  }, [ className, isReady, style, onClick ]);

  return null;
};
