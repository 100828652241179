/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import cls from 'classnames';

import { JWCustomElem } from 'src/shoppe/elements/JWCustomElem';
import { IEventSource } from 'src/shoppe/services/JWPlayer';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';
import { SvgUse } from 'src/shoppe/elements/SvgUse';

interface SkipControlProps {
  id: string;
  className?: string;
  insertAfterSelector: string;
  tooltip?: boolean;
}

export const SkipControl = ({ id, className, insertAfterSelector, tooltip }: SkipControlProps ) => {
  const { getPlayer, isComplete, updateContext } = useJWContext();

  const [ tipClass, setTipClass ] = useState<string>( null );

  const handleClick = () => {
    if ( !isComplete ) {
      updateContext({
        source: IEventSource.SKIP,
      });
      getPlayer()?.seek( getPlayer().getPosition() + 10 );
    }
  };

  const handleMouseEnter = () => {
    setTipClass( 'jw-open' );
  };

  const handleMouseLeave = () => {
    setTipClass( null );
  };

  return (
    <JWCustomElem className={className} id={id} insertAfterSelector={insertAfterSelector}>
      <div
        aria-label="Skip 10 Seconds"
        className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-rewind d-flex align-items-center justify-content-center h-100 w-100"
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* dummy svg that gets set with css */}
        <SvgUse name="" className="jw-svg-icon jw-svg-icon-ff" />

        {tooltip && (
          <div className={cls( 'jw-reset-text jw-tooltip jw-tooltip-skip', tipClass )} dir="auto">
            <div className="jw-text">Skip 10 Seconds</div>
          </div>
        )}
      </div>
    </JWCustomElem>
  );
};
