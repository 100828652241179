import React from 'react';

import { JWProvider, JWContext } from 'src/shoppe/hooks/useJWContext';
import { Props, Player } from './elements/Player';

// wrapper around the player to ensure it always has a Provider
export const JWPlayer: React.FC<Props> = ({ children, ...props }) => {
  const context = React.useContext( JWContext );

  let elem = (
    <>
      <Player {...props} />
      {children}
    </>
  );

  // if we detect that this JWPlayer already has context use that one
  // else we need to make sure that this instance of the player has context
  if ( !context ) {
    elem = <JWProvider playerId={props.playerId}>{elem}</JWProvider>;
  }

  return elem;
};
