import snakecaseKeys from 'snakecase-keys';
import ahoyTrack from 'src/explore/services/ahoy';

export enum IEvent {
  CLICK = 'Click',
  VIDEO_CHANGED = 'Video Changed',
  VIDEO_PLAYED = 'Video Played',
  VIDEO_STOPPED = 'Video Stopped',
  VIDEO_WATCHED = 'Video Watched',
}

function createTracker<T extends Record<string, unknown>>(
  name: IEvent,
  options: { snakeCase?: boolean } = { snakeCase: true }
) {
  return ( properties: T ) => {
    ahoyTrack( name, options.snakeCase ? snakecaseKeys( properties, { deep: true }) : properties );
  };
}

export default {
  createTracker,
};
