import React from 'react';
import cls from 'classnames';

import { IEventData, IEventSource } from 'src/shoppe/services/JWPlayer';
import { useJWContext, INITIAL_STATE } from 'src/shoppe/hooks/useJWContext';

import { CustomJWPlayer, CustomJWPlayerProps } from './elements/CustomJWPlayer';
import { NextControl } from './elements/NextControl';
import { ReplayControl } from './elements/ReplayControl';
import { SkipControl } from './elements/SkipControl';

import styles from './index.module.sass';

export interface Props extends Omit<CustomJWPlayerProps, 'customProps' | 'playerScript'> {
  customProps?: any;
  eventData?: IEventData;
  outroCountdown?: number;
  outroLength?: number;
  onNext?: () => void;
}

export const Player = ({
  className,
  customProps = {},
  eventData,
  outroCountdown,
  outroLength,
  onAutoStart,
  onNext,
  onPlay,
  onReady,
  onResume,
  onTime,
  onTwentyFivePercent,
  onFiftyPercent,
  onSeventyFivePercent,
  onOneHundredPercent,
  ...props
}: Props ) => {
  const { getPlayer, isVolumeSet, playerId, track, updateContext } = useJWContext();

  const [ source, setSource ] = React.useState<IEventSource>( null );

  // on unmount reset context
  React.useEffect(
    () => () => {
      updateContext({ ...INITIAL_STATE, isVolumeSet });
    },
    []
  );

  const [ , setPrevEventData ] = React.useState<Partial<IEventData>>( eventData );

  /**
   * CHANGE HANDLER
   */
  React.useEffect(() => {
    setPrevEventData(( prev ) => {
      // ensure there is a previous video to ensure this doesn't run on mount
      if ( prev && eventData && prev.videoId !== eventData.videoId ) {
        updateContext(( ctx ) => {
          track.changed(
            ctx.source,
            {
              prev,
              next: eventData,
            },
            { ref: prev?.ref }
          );
          return ctx;
        });
      }

      return eventData;
    });
  }, [ eventData?.videoId ]);

  /**
   * SETUP HANDLER
   * most browsers won't autoplay video if they are not muted
   * mute the player on initial load to allow autoplay
   */
  const handleReady = ( event: { setupTime: number; type: string; viewable: number }) => {
    if ( props.isAutoPlay && !( isVolumeSet || props.isMuted === false )) getPlayer()?.setMute( true );
    if ( onReady ) onReady( event );

    updateContext({ isReady: true });
  };

  /**
   * OUTRO LENGTH COUNTDOWN
   */
  React.useEffect(() => {
    updateContext({
      outroCountdown,
      outroLength,
    });
  }, [ outroCountdown, outroLength, updateContext ]);

  /**
   * PLAY HANDLERS
   */
  const createPlayHandler = ( interaction: IEventSource, callback?: Function ) => ( e: any ) => {
    if ( callback ) callback( e );

    updateContext(( ctx ) => {
      if ( eventData ) {
        track.played( playerId, interaction, eventData );
        setSource( interaction );
      }

      return {
        ...ctx,
        isComplete: false,
        source: interaction,
      };
    });
  };
  const handleAutoplay = createPlayHandler( IEventSource.AUTOPLAY, onAutoStart );
  const handlePlay = createPlayHandler( IEventSource.INTERACTION, onPlay );
  const handleResume = createPlayHandler( IEventSource.INTERACTION, onResume );

  /**
   * WATCH HANDLERS
   */
  const createWatchHandler = ( position: number, callback?: Function ) => ( e: any ) => {
    if ( callback ) callback( e );
    if ( eventData ) track.watched( playerId, position, source, eventData );
  };
  const handleTwentyFivePercent = createWatchHandler( 25, onTwentyFivePercent );
  const handleFiftyPercent = createWatchHandler( 50, onFiftyPercent );
  const handleSeventyFivePercent = createWatchHandler( 75, onSeventyFivePercent );
  const handleOneHundredPercent = createWatchHandler( 100, onOneHundredPercent );

  /**
   * COMPLETION HANDLERS
   */
  const createCompleteHandler = ( callback?: Function ) => ( e: any ) => {
    if ( callback ) callback( e );

    updateContext(( ctx ) => {
      // It's better for us to compare down to the tenth of a second so that we don't
      // miss any callbacks
      const complete = Math.floor( e.currentTime * 10 ) === Math.floor( e.duration * 10 );
      let interaction: IEventSource = ctx.source;
      let remainingTime = ctx.outroCountdown;

      if ( complete && onNext ) {
        onNext();
        interaction = IEventSource.AUTOPLAY;
        remainingTime = null;
      }

      return {
        ...ctx,
        isComplete: complete,
        source: interaction,
        outroCountdown: remainingTime,
      };
    });
  };
  const handleTime = createCompleteHandler( onTime );

  const handleChangeVolume = () => {
    updateContext({ isVolumeSet: true });
  };

  return (
    <>
      <CustomJWPlayer
        {...props}
        className={cls( styles.player, className )}
        customProps={customProps}
        playerScript="https://cdn.jwplayer.com/libraries/AWDMXuA6.js"
        onAutoStart={handleAutoplay}
        onFiftyPercent={handleFiftyPercent}
        onMute={handleChangeVolume}
        onOneHundredPercent={handleOneHundredPercent}
        onPlay={handlePlay}
        onReady={handleReady}
        onResume={handleResume}
        onSeventyFivePercent={handleSeventyFivePercent}
        onTime={handleTime}
        onTwentyFivePercent={handleTwentyFivePercent}
        onUnmute={handleChangeVolume}
      />
      {/* shows on mobile over player, matches classname from native rewind button */}
      <SkipControl
        className="jw-display-icon-container jw-display-icon-rewind jw-reset"
        id="jw-skip-display"
        insertAfterSelector=".jw-display-icon-display"
      />

      {/* shows on desktop in controls, matches classname from native rewind button */}
      <SkipControl
        className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-rewind"
        id="jw-skip-button"
        insertAfterSelector=".jw-button-container .jw-icon-rewind"
        tooltip
      />

      <NextControl onNext={onNext} />
      <ReplayControl />
    </>
  );
};
