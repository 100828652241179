import React, { useCallback, useState } from 'react';

import { getJWPlayer, track, IEventSource } from 'src/shoppe/services/JWPlayer';

interface IState {
  isReady: boolean;
  isVolumeSet: boolean;
  isComplete: boolean;
  outroCountdown: number | null;
  outroLength: number | null;
  source: IEventSource;
}

type IUpdateContextFunc = ( value: Partial<IState> | (( prev: IState ) => IState )) => void;

export interface IContext extends IState {
  isOutro: boolean;
  playerId: string;
  track: typeof track;
  getPlayer: () => null | any;
  updateContext: IUpdateContextFunc;
}

export const INITIAL_STATE: IState = {
  isComplete: false,
  isVolumeSet: false,
  isReady: false,
  outroCountdown: null,
  outroLength: null,
  source: IEventSource.AUTOPLAY,
};

const INITIAL_CONTEXT: IContext = {
  ...INITIAL_STATE,
  isOutro: false,
  playerId: undefined,
  track,
  getPlayer: () => null,
  updateContext: () => null,
};

export const JWContext = React.createContext<IContext | null>( null );

export const JWProvider: React.FC<{ playerId: string }> = ({ playerId, children }) => {
  const [ state, setState ] = useState( INITIAL_STATE );

  const getPlayer = useCallback(() => getJWPlayer( playerId ), [ playerId ]);

  const isOutro = state.outroCountdown !== null && state.outroCountdown < state.outroLength;

  const updateContext: IUpdateContextFunc = useCallback(( val ) => {
    if ( typeof val === 'function' ) return setState( val );

    return setState(( prev ) => ({
      ...prev,
      ...val,
    }));
  }, []);

  return (
    <JWContext.Provider
      value={{
        ...INITIAL_CONTEXT,
        ...state,
        isOutro,
        playerId,
        getPlayer,
        updateContext,
      }}
    >
      {children}
    </JWContext.Provider>
  );
};

export const useJWContext = () => {
  const context = React.useContext( JWContext );

  if ( context === undefined ) {
    throw new Error( 'useJWContext can only be used inside JWProvider' );
  }

  return context;
};
