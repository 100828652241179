import ReactJWPlayer from 'react-jw-player';
import PropTypes from 'prop-types';

import { getJWPlayer } from 'src/shoppe/services/JWPlayer';

// We want to override the componentDidUpdate method in ReactJWPlayer to prevent
// reinitializing the video player
// https://github.com/micnews/react-jw-player/blob/v1.19.0/src/react-jw-player.jsx#L7
export class CustomJWPlayer extends ReactJWPlayer {
  componentDidUpdate() {
    getJWPlayer( this.props.playerId )?.load( this.props.file || this.props.playlist );
  }
}

export type CustomJWPlayerProps = React.ComponentProps<typeof CustomJWPlayer>;

// @ts-ignore change proptypes for aspect ratio to support the full set
CustomJWPlayer.propTypes = {
  // @ts-ignore
  ...ReactJWPlayer.propTypes,
  aspectRatio: PropTypes.string,
};
