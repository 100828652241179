import * as React from 'react';

import { JWSelector } from 'src/shoppe/elements/JWSelector';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';
import { IEventSource } from 'src/shoppe/services/JWPlayer';

export const NextControl = ({ onNext }: { onNext: () => void }) => {
  const { updateContext } = useJWContext();

  const handleClick = React.useCallback(() => {
    updateContext({ source: IEventSource.INTERACTION });
    onNext();
  }, [ onNext ]);

  if ( !onNext ) return null;

  return (
    <JWSelector
      className="spec__button-next-video d-flex"
      insertBefore=".jw-button-container > :last-child"
      resetEventHandlers
      selector=".jw-icon-inline.jw-icon-next"
      onClick={handleClick}
    />
  );
};
