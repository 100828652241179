import EventTracker, { IEvent } from '../../../EventTracker';
import { getJWPlayer } from '../getJWPlayer';

export enum IEventSource {
  AUTOPLAY = 'autoplay',
  INTERACTION = 'player-interaction',
  MUTE = 'mute',
  NEXT_CARD = 'next-card',
  REPLAY = 'replay',
  SEARCH_CARD = 'search-card',
  SKIP = 'skip',
  VIDEO_CARD = 'video-card',
}

export type IEventData = {
  merchantId?: number;
  numRewatched?: number;
  playlistId?: number;
  productId?: number;
  ref?: string;
  type?: 'clip' | 'full video';
  videoId: number;
};

const changed = (
  source: IEventSource,
  {
    prev = {},
    next = {},
  }: {
    prev: Partial<IEventData>;
    next: Partial<IEventData>;
  },
  data: Partial<IEventData> = {}
) => {
  EventTracker.createTracker( IEvent.VIDEO_CHANGED )({
    source,
    prevPlaylistId: prev.playlistId,
    prevVideoId: prev.videoId,
    prevMerchantId: prev.merchantId,
    prevProductId: prev.productId,
    nextPlaylistId: next.playlistId,
    nextVideoId: next.videoId,
    nextMerchantId: next.merchantId,
    nextProductId: next.productId,
    ...data,
  });
};

const click = (
  element: any,
  playerId: string,
  source: IEventSource,
  data: Partial<IEventData> = {}
) => {
  const player = getJWPlayer( playerId );

  if ( player ) {
    const videoPosition = `${Math.round(
      ( player.getPosition() / player.getDuration() || 0 ) * 100
    )}%`;

    EventTracker.createTracker( IEvent.CLICK )({
      class: element?.className,
      href: window.location.href,
      page: window.location.pathname,
      source,
      tag: element?.type,
      text: element?.textContent,
      videoPosition,
      ...data,
    });
  }
};

const played = ( playerId: string, source: IEventSource, data: Partial<IEventData> = {}) => {
  const player = getJWPlayer( playerId );

  if ( player ) {
    const videoCurrentTime = player.getPosition();
    const videoDuration = player.getDuration();
    const videoPosition = `${Math.round(( videoCurrentTime / videoDuration || 0 ) * 100 )}%`;

    EventTracker.createTracker( IEvent.VIDEO_PLAYED )({
      source,
      videoCurrentTime,
      videoDuration,
      videoPosition,
      ...data,
    });
  }
};

const watched = (
  playerId: string,
  position: number,
  source: IEventSource,
  data: Partial<IEventData> = {}
) => {
  const player = getJWPlayer( playerId );

  if ( player ) {
    const videoCurrentTime = player.getPosition();
    const videoDuration = player.getDuration();
    const videoPosition = `${position}%`;

    EventTracker.createTracker( IEvent.VIDEO_WATCHED )({
      source,
      videoCurrentTime,
      videoDuration,
      videoPosition,
      ...data,
    });
  }
};

const stopped = ( playerId: string, source: IEventSource, data: Partial<IEventData> = {}) => {
  const player = getJWPlayer( playerId );

  if ( player ) {
    const videoCurrentTime = player.getPosition();
    const videoDuration = player.getDuration();
    const videoPosition = `${Math.round(( videoCurrentTime / videoDuration || 0 ) * 100 )}%`;

    EventTracker.createTracker( IEvent.VIDEO_STOPPED )({
      source,
      videoCurrentTime,
      videoDuration,
      videoPosition,
      ...data,
    });
  }
};

export const track = {
  changed,
  click,
  played,
  stopped,
  watched,
};
